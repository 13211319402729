import logo from './logo.svg';
import './App.css';
import { Button, Card, Table } from 'antd';
import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

function App() {
  const [dataView, setDataView] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
        fetch('https://3dprintedcat.com/api/latest-data')
            .then(res => res.json())
            .then(data => {

                console.log(data)
                // Update your state and UI here
                setDataView(data)
            });
    }, 10000); // Poll every 10 seconds, adjust as needed

    return () => clearInterval(interval);
}, []);

const navigateToUrl = (url) => {
  window.open(url, '_blank');
};

const tableData = dataView?.map((item, index) => ({
  key: index.toString(),
  ...item.data,
  ...item.data.callDetails,
}));


// Define columns for your table
const columns = [
  {
    title: 'First Name',
    dataIndex: 'fname',
    key: 'fname',
  },
  {
    title: 'Last Name',
    dataIndex: 'lname',
    key: 'lname',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Company Name',
    dataIndex: 'businessid',
    key: 'businessid',
  },
  {
    title: 'Powerlist ID',
    dataIndex: 'powerlistid',
    key: 'powerlistid',
  },
  {
    title: 'Disposition',
    dataIndex: 'disposition',
    key: 'disposition',
  },
  {
    title: 'Notes',
    dataIndex: 'note',
    key: 'note'
  },
  {
    title: 'Link to recording',
    dataIndex: 'recordingurl',
    key: 'recordingurl',
    render:(link) => (
      <>
        <Button type="primary" onClick={() => navigateToUrl(link)}>Review Audio</Button>
      </>
    )}
  // Continue adding columns for other data points you want to display...
];

function DataTable() {
  return <Table dataSource={tableData} columns={columns} />;
}

  return (
    <div className="App">
      <header className="App-header">
       <Card title={"Call List"} style={{width: "70vw"}}>{DataTable()}</Card>
      </header>
    </div>
  );
}

export default App;
